<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <defs>
      <path
        id="dr0aa"
        d="M1358.75 591.38c0-1.51-1.08-2.78-2.5-3.07v22.39a3.13 3.13 0 0 0 2.5-3.07zm-3.75-3.13h-1.25v22.5h1.25zm-2.5 0h-15v22.5h15zm-16.25 0H1335v22.5h1.25zM1340 587h10v-1.88c0-1.03-.84-1.87-1.88-1.87h-6.24c-1.04 0-1.88.84-1.88 1.88zm-6.25 1.31a3.13 3.13 0 0 0-2.5 3.07v16.25c0 1.51 1.08 2.78 2.5 3.07zm26.25 3.07v16.25a4.38 4.38 0 0 1-4.38 4.37h-21.24a4.38 4.38 0 0 1-4.38-4.38v-16.25a4.38 4.38 0 0 1 4.38-4.37h4.37v-1.88c0-1.72 1.4-3.12 3.13-3.12h6.24c1.73 0 3.13 1.4 3.13 3.13V587h4.38a4.38 4.38 0 0 1 4.37 4.38zm-12.37 11.87l.5.75.5-.75-.5-.75zm-1.28.35a.63.63 0 0 1 0-.7l1.26-1.87c.23-.35.8-.35 1.04 0l1.24 1.87c.14.21.14.49 0 .7l-1.25 1.87a.62.62 0 0 1-1.04 0zm-5.1-9.73a.63.63 0 1 0 1.25 0 .63.63 0 0 0-1.25 0zm-1.25 0a1.88 1.88 0 1 1 3.75.01 1.88 1.88 0 0 1-3.75 0z"
      />
    </defs>
    <g>
      <g transform="translate(-1329 -581)">
        <use fill="#363636" xlink:href="#dr0aa" />
        <use
          fill="#fff"
          fill-opacity="0"
          stroke="#363636"
          stroke-miterlimit="50"
          stroke-width=".1"
          xlink:href="#dr0aa"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheTripBagIcon',
};
</script>
